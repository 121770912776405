/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/



  /* General chat widget container styles */
.chat-widget {
  display: flex;
  flex-direction: column;
  height: 100%; /* Fill the parent container's height */
  width: 100%;  /* Fill the parent container's width */
  border: 1px solid #ddd; /* Optional: to show boundaries */
  background-color: #f8f9fa; /* Optional: Background color for the chat widget */
}

/* Chat conversation area */
.chat-conversation {
  flex-grow: 1; /* This allows the chat area to grow and take up remaining space */
  overflow-y: auto; /* Scroll when messages exceed available height */
  padding: 16px;
  display: flex;
  flex-direction: column-reverse; /* Keep the latest message at the bottom */

  overflow-x: hidden; /* Disable horizontal scrolling */
}

/* Chat messages styles */
.chat-messages {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Chat input area (fixed at bottom) */
.chat-input-container {
  display: flex;
  padding: 8px;
  background-color: #fff;
  border-top: 1px solid #ddd;
}

/* Chat input box */
.chat-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f1f1f1;
  outline: none;
}

/* Send button */
.send-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.send-btn i {
  font-size: 18px;
}

.send-btn:hover {
  background-color: #0056b3;
}

/* Chat message bubbles (for styling different types of messages) */
.chat-messages .chat-list {
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
}


  

.chat-list.left {
  justify-content: flex-start;
}

.chat-list.right {
  justify-content: flex-end;
}

.chat-list .conversation-list {
  max-width: 90%; /* Adjust this value as needed */
  padding: 8px 16px;
  border-radius: 12px;
  background-color: #e9ecef;
}

.chat-list.right .conversation-list {
  background-color: #e9ecef;
}

/* Optional: Style for avatar (left side messages) */
.chat-avatar {
  margin-right: 10px;
}

.chat-avatar img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.user-chat-content p {
  word-wrap: break-word; /* Break long words if necessary */
  word-break: break-word; /* Break continuous text if necessary */
  max-width: 100%; /* Ensure the paragraph takes up no more than the full width */
  margin: 0; /* Optional: reset margin if needed */
  padding: 0;
}

/* Ensure the user-chat-content container does not grow beyond its parent */
.user-chat-content {
  max-width: 90%; /* You can adjust this percentage to control the bubble width */
  background-color: #e9ecef;
  border-radius: 12px;
  padding: 10px;
  word-wrap: break-word;
  word-break: break-word;
}

.mdi-bounce:before {
  -webkit-animation: mdi-bounce 1s infinite ease-in-out;
  animation: mdi-bounce 1s infinite ease-in-out;
}

@-webkit-keyframes mdi-bounce {
  0%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes mdi-bounce {
  0%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

/* Ensure logo scales with padding */
.navbar-brand-box {
  display: flex;
  align-items: center;
}

.logo-img {
  max-height: 63px;  /* Ensure it doesn't exceed the parent's height */
  width: auto;
  padding: 5px;      /* Add some padding */
  object-fit: contain;
}


@media (max-width: 768px) {
  .logo-img {
      max-height: 40px;  /* Adjust for smaller screens */
  }
}


div.dt-scroll-body {
  border-bottom-color: var(--bs-border-color);
  border-bottom-width: var(--bs-border-width);
  border-bottom-style: none;
}

.form-control {
  /* remove max-width */
  max-width:  none;
}

.chat-messages {
  overflow-y: auto;  /* Allow scrolling */
}

.chat-input-container {
  display: flex;
  margin-top: 10px;
}

.chat-input {
  flex: 1;
  margin-right: 5px;
}